import {Component, Inject, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractWindow} from '../../../../core/abstract/AbstractWindow';
import {Guide, DocType} from '../../../../models/Guide';
import {UtilsService} from '../../../../core/services/utils.service';
import {PropertyForGuest} from "../../../../models/guestview/PropertyForGuest";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../../../core/http/api.service';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {BookingForGuest} from '../../../../models/guestview/BookingForGuest';
import {GuestUpSellOnBoarding} from '../../../../models/V3_UpSellOnboarding/GuestUpSellOnBoarding';
import {UpSellOnBoarding} from '../../../../models/V3_UpSellOnboarding/UpSellOnBoarding';
import {RequestForGuide} from '../../../../models/GuestGuide/RequestForGuide';
import {GuestGuideEndpoint} from '../../../../core/services/V3_GuestGuideEndpoint/GuestGuideEndpoint';

@Component({
  selector: 'app-guide-details',
  templateUrl: './guest-guide-details.component.html',
  styleUrls: ['./guest-guide-details.component.scss']
})
export class GuestGuideDetailsComponent extends AbstractWindow implements OnInit {

  guestUpSellOnBoarding: GuestUpSellOnBoarding;
  @Input() guide: Guide;
  @Input() property: PropertyForGuest;
  @Input() booking: BookingForGuest;
  currentUpSellOnBoarding: UpSellOnBoarding;
  DocType = DocType;

  mobileDocListOpened = false;
  showingSlider = false;
  sliderIndex = 0;

  constructor(
              private activatedRoute: ActivatedRoute,
              public translateService: TranslateService,
              public utilsService: UtilsService,
              private guestGuideEndpoint: GuestGuideEndpoint,
              private location: Location,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiService: ApiService,
              private dialogRef: MatDialogRef<GuestGuideDetailsComponent>) {
    super(utilsService);
  }

  ngOnInit(): void {
    if (this.data && this.data.guide) {
      this.guide = this.data.guide;



      let requestForGuide: RequestForGuide = new RequestForGuide();
      requestForGuide.guideId = this.guide.guideId;
      requestForGuide.propertyBookingId = this.data.bookingId;
      requestForGuide.propertyId = this.data.property.id;
      requestForGuide.language = this.translateService.currentLang;

      this.guestGuideEndpoint.getGuideFullTranslation(requestForGuide).subscribe(data => {
        console.log("--> Guide Returned with translation", data);
        this.guide = data;
        if (this.guide.upSellSection.needQty) {
          this.guide.upSellSection.relatedQty = 1;
        }
      });



      console.log("this.guide", this.guide);
    }

    if (this.data && this.data.property) {
      this.property = this.data.property;
      console.log("this.property", this.property);
    }

    if (this.data && this.data.booking) {
      this.booking = this.data.booking;
      console.log("booking", this.data.booking);
    }
    if (this.data && this.data.currentUpSellOnBoarding) {
      this.currentUpSellOnBoarding = this.data.currentUpSellOnBoarding;
    }
    /*if (this.guide.category !== 'wifi' ) {
      this._hasNext = this.hasNext();
      this._hasPrevious = this.hasPrevious();
    }*/
  }

  goBack() {
    this.location.back();
  }

  gettingThere() {
    if (this.property && this.property.poi && this.guide && this.guide.poi) {
      const url = 'https://www.google.com/maps/dir/' +
        this.property.poi.y + ',' + this.property.poi.x + '/' + this.guide.poi.y + ',' + this.guide.poi.x;
      UtilsService.openLinkInNewTab(url);
    }

  }
  divScroll(el: any, direction: string) {
    el.scrollTo({
      behavior: 'smooth',
      left: direction === 'left' ? el.scrollLeft - 340 : el.scrollLeft + 340, // 330 is the bloc width set on the css
    });
  }

  getWifiCode() {
    // return "https://www.yaago.com";
    // console.log("--> Wifi Guide", this.guide);
    let returnString = 'WIFI:S:' + this.guide.translations[0].title;
    if (this.guide.translations[0].wifiSecurityProtocol) {
      returnString += ';T:' + this.guide.translations[0].wifiSecurityProtocol;
    }
    if (this.guide.translations[0].description) {
      returnString += ';P:' + this.guide.translations[0].description + ';';
    }
    if (this.guide.translations[0].wifiHidden) {
      returnString += 'H:true;';
    } else {
      returnString += ';';
    }
    // console.log("-->Return String", returnString);
    return returnString;
  }

  getWifiUrl() {
    let returnString = 'wifi://' + this.guide.translations[0].description + '@' + this.guide.translations[0].title + ';';
    if (this.guide.translations[0].wifiHidden) {
      returnString += 'S:true;';
    } else {
      returnString += ';';
    }
    return returnString;
  }

  getCleanTextToRead(desc: string) {
    if (!desc) {
      return;
    }
    let textToSpeech = desc.replace(/<[^>]*>/g, ' ');
    textToSpeech = textToSpeech.replace(/&nbsp;/gi, ' ');
    // textToSpeech = textToSpeech.replace(/^https?:\/\/.*[\r\n]*/g, ' ');
    return textToSpeech;
  }

  showSlider(i) {
    this.sliderIndex = i;
    this.showingSlider = true;
  }

  next() {
    if (!this.guide || !this.guide.documents || this.guide.documents.length === 0) {
      return;
    }
    if (this.sliderIndex === this.guide.documents.length - 1) {
      this.sliderIndex = 0;
    } else {
      this.sliderIndex++;
    }
  }

  previous() {
    if (!this.guide || !this.guide.documents || this.guide.documents.length === 0) {
      return;
    }
    if (this.sliderIndex === 0) {
      this.sliderIndex = this.guide.documents.length - 1;
    } else {
      this.sliderIndex--;
    }
  }
  openLink(url: string) {
    this.apiService.upSellStatistics(this.property.id).subscribe( res => {

    });

    /*if (this.booking) {
      if (this.booking.guests && this.booking.guests.length > 0) {
        if (this.booking.guests[0].firstName) {
          url+= "?metadata[firstName]="+this.booking.guests[0].firstName;
        }
        if (this.booking.guests[0].lastName) {
          url+= "&"+"metadata[lastName]=" + this.booking.guests[0].lastName;
        }
        if (this.property.title) {
          url+= "&"+"metadata[property]=" + this.property.title;
        }
        if (this.booking.startDate) {
          url+= "&"+"metadata[startDate]=" + this.booking.startDate;
        }
        if (this.booking.endDate) {
          url+= "&"+"metadata[endDate]=" + this.booking.endDate;
        }
      }
    }*/
    console.log("URL", url);
    UtilsService.openLinkInNewTab(url);
  }

  closeWindow() {
    this.dialogRef.close();
  }

  addUpSellOnboarding(guide: Guide, qty: number = 0) {
    if (localStorage.getItem("guestUpSellOnBoarding") != null) {
       this.guestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
       if (this.booking.id != this.guestUpSellOnBoarding.propertyBookingId) {
         localStorage.removeItem("guestUpSellOnBoarding");
        this.resetGuestUpSellOnBoarding();
       }
    } else {
      this.resetGuestUpSellOnBoarding();
    }
    if (this.guestUpSellOnBoarding.guides == null) {
      this.guestUpSellOnBoarding.guides = [];
    }
    this.guestUpSellOnBoarding.guides = this.guestUpSellOnBoarding.guides.filter((object) => object.guideId !== guide.guideId );
    this.guestUpSellOnBoarding.guides.push(guide);
    this.guestUpSellOnBoarding.qty = qty;
    localStorage.setItem("guestUpSellOnBoarding", JSON.stringify(this.guestUpSellOnBoarding));
  }

  checkIfUpsellItemExists(guideId: string) {
    let check = false;
    if (localStorage.getItem("guestUpSellOnBoarding") != null) {
      this.guestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
      if (this.booking.id != this.guestUpSellOnBoarding.propertyBookingId) {
        localStorage.removeItem("guestUpSellOnBoarding");
      } else {

        if (this.guestUpSellOnBoarding.guides != null) {
          check = this.guestUpSellOnBoarding.guides.filter(g => g.guideId === guideId).length > 0;
          if (check && this.guide.upSellSection.needQty) {
            this.guide.upSellSection.relatedQty = this.guestUpSellOnBoarding.guides.filter(g => g.guideId === guideId)[0].upSellSection.relatedQty;
          }
        }

      }
    }
    return check;
  }

  removeUpSellOnboarding(guide: Guide) {
    if (localStorage.getItem("guestUpSellOnBoarding") != null) {
      this.guestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
      if (this.booking.id != this.guestUpSellOnBoarding.propertyBookingId) {
        localStorage.removeItem("guestUpSellOnBoarding");
      } else {
        if (this.guestUpSellOnBoarding.guides != null) {
          this.guestUpSellOnBoarding.guides = this.guestUpSellOnBoarding.guides.filter((object) => object.guideId !== guide.guideId );
        }
      }
      if (this.guestUpSellOnBoarding.guides.length === 0) {
        localStorage.removeItem("guestUpSellOnBoarding");
      } else {
        localStorage.setItem("guestUpSellOnBoarding", JSON.stringify(this.guestUpSellOnBoarding));
      }
    }
  }

  resetGuestUpSellOnBoarding() {
    this.guestUpSellOnBoarding = new GuestUpSellOnBoarding();
    this.guestUpSellOnBoarding.propertyBookingId = this.booking.id;
    this.guestUpSellOnBoarding.language = this.translateService.currentLang;
    this.guestUpSellOnBoarding.guides = [];
    this.guestUpSellOnBoarding.returnUrl = this.location.path(true);
  }

  addQty(guide: Guide) {
    guide.upSellSection.relatedQty ++;
  }
  removeQty(guide: Guide) {
    if (guide.upSellSection.relatedQty > 0) {
      guide.upSellSection.relatedQty --;
    }
  }


}
