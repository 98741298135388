/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-recommendations-map-desktop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../shared/components/leaflet-map/leaflet-map.component.ngfactory";
import * as i4 from "../../../../../shared/components/leaflet-map/leaflet-map.component";
import * as i5 from "../../../../../core/services/utils.service";
import * as i6 from "../../../../../core/http/api.service";
import * as i7 from "../../../../../core/services/guest.service";
import * as i8 from "../../guest-recommendation-v2-mini-card/guest-recommendation-v2-mini-card.component.ngfactory";
import * as i9 from "../../guest-recommendation-v2-mini-card/guest-recommendation-v2-mini-card.component";
import * as i10 from "../../guest-recommendations-card/guest-recommendations-card.component.ngfactory";
import * as i11 from "../../guest-recommendations-card/guest-recommendations-card.component";
import * as i12 from "../../../../../core/services/language.service";
import * as i13 from "../../../../../core/services/collect-data-service.service";
import * as i14 from "../../guest-recommendations-reviews/guest-recommendations-reviews.component.ngfactory";
import * as i15 from "../../guest-recommendations-reviews/guest-recommendations-reviews.component";
import * as i16 from "./guest-recommendations-map-desktop.component";
import * as i17 from "@angular/router";
var styles_GuestRecommendationsMapDesktopComponent = [i0.styles];
var RenderType_GuestRecommendationsMapDesktopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestRecommendationsMapDesktopComponent, data: {} });
export { RenderType_GuestRecommendationsMapDesktopComponent as RenderType_GuestRecommendationsMapDesktopComponent };
export function View_GuestRecommendationsMapDesktopComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { guruMapElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "map-desktop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "map-wrapper"], ["id", "map-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "location-search-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "flex-row flex-align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "flex-row flex-align-center margin-right-24 clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeMap() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "left"], ["class", "margin-right-8"], ["src", "/assets/icon/icon-arrow-left.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "roboto bold Small txt-color-grvogue"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "flex-row flex-align-center location-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", "search"], ["class", "margin-right-12 margin-left-16"], ["src", "/assets/icon/search.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "input", [["class", "width-75 padding-bottom-12 padding-top-12"], ["id", "fullAddress"], ["type", "text"]], [[8, "placeholder", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-leaflet-map", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_LeafletMapComponent_0, i3.RenderType_LeafletMapComponent)), i1.ɵdid(15, 4833280, null, 0, i4.LeafletMapComponent, [i5.UtilsService, i6.ApiService, i2.TranslateService, i7.GuestService], { relatedPoiForDistanceCalculation: [0, "relatedPoiForDistanceCalculation"], centerOfTheMap: [1, "centerOfTheMap"], propertyPoi: [2, "propertyPoi"], whereIamPoi: [3, "whereIamPoi"], mapId: [4, "mapId"], properties: [5, "properties"], property: [6, "property"], corporate: [7, "corporate"], showAllProperties: [8, "showAllProperties"], showProperty: [9, "showProperty"], showOwner: [10, "showOwner"], showFilterReco: [11, "showFilterReco"], showCommunityReco: [12, "showCommunityReco"], showRecoCorporate: [13, "showRecoCorporate"], showRecoInfluencer: [14, "showRecoInfluencer"], showRecoYaago: [15, "showRecoYaago"], showRecoTourism: [16, "showRecoTourism"], showZoomControl: [17, "showZoomControl"], language: [18, "language"], showChecks: [19, "showChecks"], recapVersion: [20, "recapVersion"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-guest-recommendation-v2-mini-card", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_GuestRecommendationV2MiniCardComponent_0, i8.RenderType_GuestRecommendationV2MiniCardComponent)), i1.ɵdid(17, 114688, null, 0, i9.GuestRecommendationV2MiniCardComponent, [i7.GuestService, i2.TranslateService, i5.UtilsService], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-guest-recommendations-card", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_GuestRecommendationsCardComponent_0, i10.RenderType_GuestRecommendationsCardComponent)), i1.ɵdid(19, 245760, null, 0, i11.GuestRecommendationsCardComponent, [i5.UtilsService, i7.GuestService, i12.LanguageManagementService, i13.CollectDataServiceService], { bookingId: [0, "bookingId"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "app-guest-recommendations-reviews", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_GuestRecommendationsReviewsComponent_0, i14.RenderType_GuestRecommendationsReviewsComponent)), i1.ɵdid(21, 114688, null, 0, i15.GuestRecommendationsReviewsComponent, [i7.GuestService, i5.UtilsService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.centerOfTheMap; var currVal_3 = _co.centerOfTheMap; var currVal_4 = _co.propertyPoi; var currVal_5 = _co.whereIamPoi; var currVal_6 = "mapRecoDesktop"; var currVal_7 = _co.propertiesForMap; var currVal_8 = _co.property; var currVal_9 = _co.corporate; var currVal_10 = true; var currVal_11 = true; var currVal_12 = _co.showOwner; var currVal_13 = true; var currVal_14 = _co.showCommunityReco; var currVal_15 = true; var currVal_16 = _co.showRecoInfluencer; var currVal_17 = _co.showRecoYaago; var currVal_18 = true; var currVal_19 = _co.showZoomControl; var currVal_20 = _co.language; var currVal_21 = _co.showRecoYaago; var currVal_22 = _co.recapVersion; _ck(_v, 15, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22]); _ck(_v, 17, 0); var currVal_23 = ((_co.booking == null) ? null : _co.booking.id); _ck(_v, 19, 0, currVal_23); _ck(_v, 21, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("guest-recommendation.return")); _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("guest-recommendation.indicate-place")), ""); _ck(_v, 12, 0, currVal_1); }); }
export function View_GuestRecommendationsMapDesktopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-map-desktop", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestRecommendationsMapDesktopComponent_0, RenderType_GuestRecommendationsMapDesktopComponent)), i1.ɵdid(1, 114688, null, 0, i16.GuestRecommendationsMapDesktopComponent, [i5.UtilsService, i1.NgZone, i1.Renderer2, i17.Router, i7.GuestService, i2.TranslateService, i12.LanguageManagementService, i13.CollectDataServiceService, i6.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestRecommendationsMapDesktopComponentNgFactory = i1.ɵccf("app-guest-recommendations-map-desktop", i16.GuestRecommendationsMapDesktopComponent, View_GuestRecommendationsMapDesktopComponent_Host_0, {}, {}, []);
export { GuestRecommendationsMapDesktopComponentNgFactory as GuestRecommendationsMapDesktopComponentNgFactory };
