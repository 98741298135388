import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { GuestService } from '../../core/services/guest.service';
import { InternalPropertyService } from '../../core/services/internal.property.service';
import { GuestServiceIntern } from './guest.service';
import {SecurityService} from '../../core/services/SecurityService';
import {TranslateService} from '@ngx-translate/core';
import {AbstractWindow} from '../../core/abstract/AbstractWindow';
import {UtilsService} from '../../core/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class GuestResolverService extends AbstractWindow implements Resolve<any> {

  constructor(
    public utilsService: UtilsService,
    private guestService: GuestService,
    private router: Router,
    private propertyService: InternalPropertyService,
    private internGuestService: GuestServiceIntern,
    private translateService: TranslateService,
    private securityService: SecurityService
    // private errorHandler: BugsnagChunkLoadErrorHandler
  ) {
    super(utilsService);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {


    const bookingId = route.children[0].params.bookingId;
    let lang = null;
    if (route.children[0].queryParams.lang) {
      lang = route.children[0].queryParams.lang;
      this.translateService.use(lang);
    } else {
      // if the language is not specified then leave the back end to force the language to the guest one ...
      // lang = this.translateService.currentLang;
    }
    // if you put browser language you will always override guest language
   /* if (!route.children[0].queryParams.lang) {
      lang = this.translateService.currentLang;
    } else {
      lang = route.children[0].queryParams.lang;
      this.translateService.use(lang);
    }*/
    //console.log("resolver language", lang);
    //console.log("route.children[0].queryParams.lang", route.children[0].queryParams.lang);



    /*if (!navigator.onLine) {
      const newRes = JSON.parse(localStorage.getItem('PropertyAndBookingForGuestDto'));
      return of(newRes);
    }*/

    return this.guestService.getPropertyAndBookingForGuest(bookingId, lang).pipe(
      mergeMap(res => {
        if (res.takenLanguage) {
          this.translateService.use(res.takenLanguage);
        }
        this.propertyService.setCurrentProperty(res.property);
        this.propertyService.localProperty = res.property;
        this.internGuestService.setBookingForGuest(res.booking);
        this.guestService.sendCorporate(res.corporate);
        this.propertyService.setCurrentCorporate(res.corporate);

        this.utilsService.manageCorporateColors(res.corporate);

        console.log("===>>> RES", res);
        localStorage.removeItem('PropertyAndBookingForGuestDto');
        //localStorage.setItem('PropertyAndBookingForGuestDto', JSON.stringify(res));

        // console.log("isDesktopMode", this.isDesktopMode());
        // console.log("window.innerWidth", window.innerWidth);
        // console.log("welcomewasclose", GuestService.welcomeWasClosed);

        // console.log(state.url.substr(state.url.indexOf(bookingId) + bookingId.length + 1));
        let endOfRoute =  state.url.substr(state.url.indexOf(bookingId) + bookingId.length );
        if (endOfRoute.startsWith('?')) {
          endOfRoute = '';
        }

        // 2024-04-19 don't show guide when the checkin on line has not been done !!!
        //if (state.url.indexOf('/guides/') > 0) {

        //} else {
          if (!this.isDesktopMode()) {
            // Check for the WelcomeScreen ...
            if (res.corporate.homePageSettings && !GuestService.welcomeWasClosed) {
              if (state.url.indexOf('/mobile-welcome') < 0) {
                // console.log("step0");
                // return this.router.navigate(['/guest/' + bookingId + "/mobile-welcome"]);
                return lang ? this.router.navigate(['/guest/' + bookingId + "/mobile-welcome"], {queryParams: {lang: lang}}) :
                  this.router.navigate(['/guest/' + bookingId + "/mobile-welcome"]);
              }
            } else if (res.booking == null && state.url.indexOf('/mobile-password') < 0 && this.securityService.getSecurityPassword() !== bookingId
              && (res.corporate.bookletMasterKey && res.corporate.bookletMasterKey !== '' || state.url.indexOf('/4guest') > 0)) {
              // console.log("step1");
              return this.router.navigate(['/guest/' + bookingId + '/mobile-password']);
            } else if (state.url.indexOf('/mobile') < 0 && state.url.indexOf('/mobile-online-check-in') < 0) {
              // console.log("step2 endofroute: ", endOfRoute);

              return lang ? this.router.navigate(['/guest/' + bookingId + "/mobile"], {queryParams: {lang: lang}}) :
                this.router.navigate(['/guest/' + bookingId + "/mobile"]);

              // return this.router.navigate(['/guest/' + bookingId + "/mobile" + endOfRoute]);
            } else if (res.booking != null) {
              // console.log("step3");
              // Redirect for the PreCheckIn or the CheckInOnline
              if (((res.property.lightOnLinePreCheck && !res.booking.lightOnLinePreCheckDone)
                  || (res.property.checkInOnline && res.booking.onLineCheck != null && res.booking.onLineCheck.checkStatus != null))
                && (res.booking.onLineCheck == null || (res.booking.onLineCheck != null && !['VALIDATED', 'SKIPPED'].includes(res.booking.onLineCheck.checkStatus)))) {
                if (state.url.indexOf('/mobile-online-check-in') < 0) {
                  // console.log("step4");
                  return this.router.navigate(['/guest/' + bookingId + "/mobile-online-check-in"]);
                }
              }
            }
          } else {
            // console.log("corpo", res.corporate.bookletMasterKey && this.securityService.getSecurityPassword() !== bookingId);
            if ((res.booking != null || state.url.indexOf('/4guest') > 0) && res.corporate.homePageSettings && !GuestService.welcomeWasClosed) {
              if (state.url.indexOf('/livret-welcome') < 0) {
                return lang ? this.router.navigate(['/guest/' + bookingId + "/livret-welcome"], {queryParams: {lang: lang}}) :
                  this.router.navigate(['/guest/' + bookingId + "/livret-welcome"]);
              }
            } else if (res.booking == null && state.url.indexOf('/ask4pwd') < 0 && this.securityService.getSecurityPassword() !== bookingId
              && (res.corporate.bookletMasterKey && res.corporate.bookletMasterKey !== '' || state.url.indexOf('/4guest') > 0)) {
              // console.log("force ask password");
              return lang ? this.router.navigate(['/pwd/' + bookingId + '/ask4pwd'], {queryParams: {lang: lang}}) :
                this.router.navigate(['/pwd/' + bookingId + '/ask4pwd']);
            } else if (res.booking != null) {
              // console.log("redirect to mobile-online-check-in", res.booking.onLineCheck.checkStatus);
              // Redirect for the PreCheckIn or the CheckInOnline
              if (((res.property.lightOnLinePreCheck && !res.booking.lightOnLinePreCheckDone)
                  || (res.property.checkInOnline && res.booking.onLineCheck != null && res.booking.onLineCheck.checkStatus != null))
                && (res.booking.onLineCheck == null || (res.booking.onLineCheck != null && !['VALIDATED', 'SKIPPED'].includes(res.booking.onLineCheck.checkStatus)))) {
                if (state.url.indexOf('/mobile-online-check-in') < 0) {
                  // console.log("redirect to mobile-online-check-in");
                  return this.router.navigate(['/guest/' + bookingId + "/mobile-online-check-in"]);
                }
              }
            }
          }
        //}

        let urlForGuides = false;
        if (state.url.indexOf('/guides/') > 0) {
          urlForGuides = true;
          if (!this.isDesktopMode() && state.url.indexOf('/mobile/guides/') < 0) {
            this.router.navigate([state.url.replace('guides/','mobile/guides/')]);
          }
        }
        if (res.booking != null) {

          /*return lang ? this.router.navigate(['/guest/' + bookingId], { queryParams: { lang: lang } }):
            this.router.navigate(['/guest/' + bookingId]);*/
          /*if (res.property.lightOnLinePreCheck && !res.booking.lightOnLinePreCheckDone && state.url.indexOf('/messaging') < 0) {
            return this.router.navigate(['/onlinecheck/light/' + bookingId]);
          } else if (res.booking.onLineCheck != null && res.booking.onLineCheck.checkStatus != null && !['VALIDATED', 'SKIPPED'].includes(res.booking.onLineCheck.checkStatus) && state.url.indexOf('/messaging') < 0) {
            //return this.router.navigate(['/onlinecheck/' + bookingId]);
            return this.router.navigate(['/guest/' + bookingId + "/online-check-in"]);
          }*/
        }
       /* if (!res.property.host.subscriptionForLivretSeul) {
          console.log('Security Password: ', this.securityService.getSecurityPassword());
          if (!urlForGuides && res.booking == null && route && this.securityService.getSecurityPassword() !== bookingId && res.corporate != null  && res.corporate.homePageSettings === undefined) {
            console.log('route', route.children[0].url);
            console.log('No Booking');
            console.log('PageSettings', res.corporate.homePageSettings);
            if (this.isDesktopMode()  && res.corporate.bookletMasterKey) {
              return this.router.navigate(['/pwd/' + bookingId + '/ask4pwd']);
            } else {
              if (state.url.indexOf('/mobile-password') < 0 && res.corporate.bookletMasterKey) {
                return this.router.navigate(['/guest/' + bookingId + '/mobile-password']);
              }
            }
          } else if (!urlForGuides && res.booking == null && route && this.securityService.getSecurityPassword() !== bookingId && res.corporate != null && res.corporate.bookletMasterKey != null && res.corporate.homePageSettings && state.url.split('/')[state.url.split('/').length - 1] !== 'livret-welcome') {
            return this.router.navigate(['/guest/' + bookingId + '/livret-welcome']);
          }
        }*/

        /*if (res.booking != null) {
          console.log('Current Routes: ', state.url);
          console.log('res.booking.welcomePageSeen', res.booking.welcomePageSeen);
          if (res.booking.welcomePageSeen === false && res.corporate.homePageSettings !== undefined && state.url.split('/')[state.url.split('/').length - 1] !== 'livret-welcome') {
            console.log('res.corporate.homePageSettings', res.corporate.homePageSettings);
            return this.router.navigate(['/guest/' + bookingId + '/livret-welcome']);
          } else if (res.booking.welcomePageSeen === false && state.url.split('/')[state.url.split('/').length - 1] === 'livret-welcome' && !res.corporate.homePageSettings) {
            return this.router.navigate(['/guest/' + bookingId]);
          } else if (res.booking.welcomePageSeen === true && state.url.split('/')[state.url.split('/').length - 1] === 'livret-welcome') {
            console.log('imhere');
            return this.router.navigate(['/guest/' + bookingId]);
          }
        }*/

        // this.errorHandler.addMetadata(res.property.id, res.booking.id);
        return of(res);
      }),
      catchError((err) => {
        console.log('Error:', err);
        return this.router.navigate(['/misc/lost']);
      })
    );
  }

}
