/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./guest-recommendations-map-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../shared/components/leaflet-map/leaflet-map.component.ngfactory";
import * as i6 from "../../../../../shared/components/leaflet-map/leaflet-map.component";
import * as i7 from "../../../../../core/services/utils.service";
import * as i8 from "../../../../../core/http/api.service";
import * as i9 from "../../../../../core/services/guest.service";
import * as i10 from "../../guest-recommendation-v2-mini-card/guest-recommendation-v2-mini-card.component.ngfactory";
import * as i11 from "../../guest-recommendation-v2-mini-card/guest-recommendation-v2-mini-card.component";
import * as i12 from "../../guest-recommendations-card/guest-recommendations-card.component.ngfactory";
import * as i13 from "../../guest-recommendations-card/guest-recommendations-card.component";
import * as i14 from "../../../../../core/services/language.service";
import * as i15 from "../../../../../core/services/collect-data-service.service";
import * as i16 from "../../guest-recommendations-reviews/guest-recommendations-reviews.component.ngfactory";
import * as i17 from "../../guest-recommendations-reviews/guest-recommendations-reviews.component";
import * as i18 from "./guest-recommendations-map-mobile.component";
import * as i19 from "@angular/router";
var styles_GuestRecommendationsMapMobileComponent = [i0.styles];
var RenderType_GuestRecommendationsMapMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GuestRecommendationsMapMobileComponent, data: {} });
export { RenderType_GuestRecommendationsMapMobileComponent as RenderType_GuestRecommendationsMapMobileComponent };
export function View_GuestRecommendationsMapMobileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { guruMapElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "map-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "map-wrapper"], ["id", "map-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "location-search-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "flex-row flex-align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "flex-row flex-align-center margin-right-24 clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeMap() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "y3-color-desktop-bandeau-font mat-icon notranslate"], ["role", "img"], ["style", "font-size: 30px; height: unset"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_left"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "roboto bold Small y3-color-desktop-bandeau-font"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "flex-row flex-align-center location-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", "search"], ["class", "margin-right-8 "], ["src", "/assets/icon/search.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "input", [["class", "width-75 padding-bottom-12 padding-top-12"], ["id", "fullAddress"], ["type", "text"]], [[8, "placeholder", 0]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-leaflet-map", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_LeafletMapComponent_0, i5.RenderType_LeafletMapComponent)), i1.ɵdid(17, 4833280, null, 0, i6.LeafletMapComponent, [i7.UtilsService, i8.ApiService, i4.TranslateService, i9.GuestService], { relatedPoiForDistanceCalculation: [0, "relatedPoiForDistanceCalculation"], centerOfTheMap: [1, "centerOfTheMap"], propertyPoi: [2, "propertyPoi"], whereIamPoi: [3, "whereIamPoi"], mapId: [4, "mapId"], properties: [5, "properties"], property: [6, "property"], corporate: [7, "corporate"], showAllProperties: [8, "showAllProperties"], showProperty: [9, "showProperty"], showOwner: [10, "showOwner"], showFilterReco: [11, "showFilterReco"], showCommunityReco: [12, "showCommunityReco"], showRecoCorporate: [13, "showRecoCorporate"], showRecoInfluencer: [14, "showRecoInfluencer"], showRecoYaago: [15, "showRecoYaago"], showRecoTourism: [16, "showRecoTourism"], showZoomControl: [17, "showZoomControl"], language: [18, "language"], showChecks: [19, "showChecks"], recapVersion: [20, "recapVersion"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-guest-recommendation-v2-mini-card", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).getScreenWidth($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_GuestRecommendationV2MiniCardComponent_0, i10.RenderType_GuestRecommendationV2MiniCardComponent)), i1.ɵdid(19, 114688, null, 0, i11.GuestRecommendationV2MiniCardComponent, [i9.GuestService, i4.TranslateService, i7.UtilsService], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "app-guest-recommendations-card", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_GuestRecommendationsCardComponent_0, i12.RenderType_GuestRecommendationsCardComponent)), i1.ɵdid(21, 245760, null, 0, i13.GuestRecommendationsCardComponent, [i7.UtilsService, i9.GuestService, i14.LanguageManagementService, i15.CollectDataServiceService], { bookingId: [0, "bookingId"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "app-guest-recommendations-reviews", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_GuestRecommendationsReviewsComponent_0, i16.RenderType_GuestRecommendationsReviewsComponent)), i1.ɵdid(23, 114688, null, 0, i17.GuestRecommendationsReviewsComponent, [i9.GuestService, i7.UtilsService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 7, 0); var currVal_4 = _co.centerOfTheMap; var currVal_5 = _co.centerOfTheMap; var currVal_6 = _co.propertyPoi; var currVal_7 = _co.whereIamPoi; var currVal_8 = "mapRecoMobile"; var currVal_9 = _co.propertiesForMap; var currVal_10 = _co.property; var currVal_11 = _co.corporate; var currVal_12 = true; var currVal_13 = true; var currVal_14 = _co.showOwner; var currVal_15 = true; var currVal_16 = _co.showCommunityReco; var currVal_17 = true; var currVal_18 = _co.showRecoInfluencer; var currVal_19 = _co.showRecoYaago; var currVal_20 = true; var currVal_21 = _co.showZoomControl; var currVal_22 = _co.language; var currVal_23 = _co.showRecoYaago; var currVal_24 = _co.recapVersion; _ck(_v, 17, 1, [currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23, currVal_24]); _ck(_v, 19, 0); var currVal_25 = ((_co.booking == null) ? null : _co.booking.id); _ck(_v, 21, 0, currVal_25); _ck(_v, 23, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).inline; var currVal_1 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("guest-recommendation.return")); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("guest-recommendation.indicate-place")), ""); _ck(_v, 14, 0, currVal_3); }); }
export function View_GuestRecommendationsMapMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-guest-recommendations-map-mobile", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).isDesktopMode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GuestRecommendationsMapMobileComponent_0, RenderType_GuestRecommendationsMapMobileComponent)), i1.ɵdid(1, 114688, null, 0, i18.GuestRecommendationsMapMobileComponent, [i7.UtilsService, i1.NgZone, i1.Renderer2, i19.Router, i9.GuestService, i4.TranslateService, i14.LanguageManagementService, i15.CollectDataServiceService, i8.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuestRecommendationsMapMobileComponentNgFactory = i1.ɵccf("app-guest-recommendations-map-mobile", i18.GuestRecommendationsMapMobileComponent, View_GuestRecommendationsMapMobileComponent_Host_0, {}, {}, []);
export { GuestRecommendationsMapMobileComponentNgFactory as GuestRecommendationsMapMobileComponentNgFactory };
