import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';

import {Property} from '../../../models/Property';
import {Poi} from '../../../models/Poi';
import {Recommendation} from '../../../models/guestview/Recommendation';
import {Comment} from '../../../models/guestview/Comment';
import {RecommendationSearchRequestDto} from '../../../models/guestview/RecommendationSearchRequestDto';
import {ApiService, RequestForTranslation} from '../../../core/http/api.service';
import {Corporate} from '../../../models/guestview/Corporate';
import {RecommendationCategory} from '../../../models/guestview/RecommendationCategory';
import {Tags} from '../../../models/guestview/Tags';
import {GuestService} from '../../../core/services/guest.service';
import {TranslateService} from '@ngx-translate/core';
import {backEndComment, CommentForGuest} from '../../../models/guestview/CommentForGuest';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {UtilsService} from '../../../core/services/utils.service';
import {ButtonType} from '../../../shared/components/button/button.component';
import {Icons} from "../../../models/icons";

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent extends AbstractWindow implements OnInit, AfterViewInit, OnChanges {

  ButtonType = ButtonType;
  Icons = Icons;

  @Input() relatedPoiForDistanceCalculation: Poi;
  @Input() centerOfTheMap: Poi;
  @Input() propertyPoi: Poi;
  @Input() whereIamPoi: Poi;
  @Input() mapId = 'map';

  @Input() properties: Property[] = [];
  @Input() property: Property;
  @Input() corporate: Corporate;

  @Input() showAllProperties      = false;
  @Input() showProperty           = false;
  @Input() showOwner              = true;

  @Input() showFilterReco         = false;

  @Input() showCommunityReco      = false;
  @Input() showRecoCorporate      = false;
  @Input() showRecoInfluencer     = false;
  @Input() showRecoYaago          = false;
  @Input() showRecoTourism        = false;
  @Input() showZoomControl        = true;
  @Input() maxDistance            = 50;
  @Input() language               = 'fr';
  @Input() showChecks             = false;
  @Input() recapVersion           = 'v2';
  @Input() defaultZoom            = 14;
  @Output() recommendationSelected = new EventEmitter<Recommendation>();

  // For recommendation Search
  private recoSearchDto = new RecommendationSearchRequestDto();
  filterTagsIds: string[] = [];
  recommendationList: Recommendation[] = [];
  categories: any[] = new RecommendationCategory().list;
  categoryTags: Tags[] = [];
  allTags: Tags[] = [];

  // For the Map
  private map;
  initializationMapDone: boolean = false;
  markerCluster: any;
  //markerGroup: any;
  //markerGroupFirstDone = false;
  markers: any[] = [];




  isLoadingReviews = false;
  currentRecommendation: Recommendation | null;
  currentComments: backEndComment[];
  doWeOpenRecommendationCard = false;
  doWeOpenRecommendationCardMini = false;

  // Filters
  showFilters = false;
  selectedCategory: any;

  // Set Markers
  public iconMarkers: any;

  // Reco Card
  _hasNext: boolean;
  _hasPrevious: boolean;
  _isFromCommunity: boolean;
  _isFromMyHost: boolean;
  index = 0;
  tags: Tags[] = [];
  readMore: boolean;

  transports: any;
  cars:  any;
  activities:  any;
  spots:  any;
  pubAndRestaurants:  any;
  health:  any;
  essentials:  any;
  beauty:  any;
  sports:  any;
  shops:  any;
  parcsAndOutdoors: any;
  events: any;
  localProducers:  any;
  tesla: any;
  charger: any;
  services: any;
  favorites: any;



  yaago_parcsAndOutdoors : any;
  yaago_shops : any;
  yaago_sports : any;
  yaago_beauty : any;
  yaago_essentials : any;
  yaago_health : any;
  yaago_pubAndRestaurants : any;
  yaago_spots : any;
  yaago_activities : any;
  yaago_cars : any;
  yaago_transports : any;
  yaago_events : any;
  yaago_localProducers : any;
  yaago_tesla: any;
  yaago_charger: any;
  yaago_services: any;
  yaago_favorites: any;

  insta_parcsAndOutdoors : any;
  insta_shops: any;
  insta_sports : any;
  insta_beauty : any;
  insta_essentials : any;
  insta_health : any;
  insta_pubAndRestaurants : any;
  insta_spots : any;
  insta_activities : any;
  insta_cars : any;
  insta_transports : any;
  insta_events : any;
  insta_localProducers: any;
  insta_services: any;
  insta_favorites: any;

  constructor(
              public utilsService: UtilsService,
              private apiService: ApiService,
              private translateService: TranslateService,
              private guestService: GuestService) {
    super(utilsService);
  }

  ngOnInit() {
    this.guestService.getTags().subscribe(
      res => {
        this.allTags = res;
      }
    );
    this.initRecommendationMarkers();
    this.categories.splice(this.categories.length - 1, 1);
    this.recommendationSelected.subscribe( res => {
      if (this.currentRecommendation == res) {
        console.log("close reco");
        this.doWeOpenRecommendationCard = false;
        this.doWeOpenRecommendationCardMini = false;
        this.currentRecommendation = null;
      } else {
        console.log("reception de res", res);
        this.currentRecommendation = res;

        const requestForTranslation: RequestForTranslation = new RequestForTranslation();
        let personIds: string[] = [];
        if (this.currentRecommendation != null) {
          requestForTranslation.recommendationId = this.currentRecommendation.id;
          if (this.currentRecommendation.reviews != null) {
            this.currentRecommendation.reviews.forEach(r => {
              if (personIds.indexOf(r.personId) < 0) {
                personIds.push(r.personId);
              }
            });
          }
        }
        requestForTranslation.personIds = personIds;
        requestForTranslation.lang = this.language;
        this.apiService.getTranslatedComment(requestForTranslation).subscribe(res => {
          this.currentComments = res;
        });
        this.parseTags();
        if (this.currentRecommendation != null) {
          this._hasNext = this.currentRecommendation.photos.length > 1;
        }
        this._hasPrevious = false;
        if (this.isDesktopMode()) {
          this.doWeOpenRecommendationCard = true;
        } else {
          this.doWeOpenRecommendationCardMini = true;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngChanges: ', changes);

    if (this.centerOfTheMap) {
      if (this.initializationMapDone) {
        if (this.map != null) {
          this.map.panTo([this.centerOfTheMap.y, this.centerOfTheMap.x]);
          this.map.setZoom(this.defaultZoom);
        }
      }
      this.loadReco();
    }

  }

  ngAfterViewInit() {

    setTimeout( () => {
    this.initMap();
    if (this.properties || this.propertyPoi) {
      this.initProperties();
    }

    if (this.centerOfTheMap) {
      if (this.initializationMapDone) {
        if (this.map != null) {
          this.map.panTo([this.centerOfTheMap.y, this.centerOfTheMap.x]);
          this.map.setZoom(this.defaultZoom);
        }
      }
      this.loadReco();
    }
    /*if (this.corporate && this.corporate.includeCommunityRecommendation) {
      this.showCommunityReco = this.corporate.includeCommunityRecommendation;
    }*/
    }, 500);
  }

  loadReco() {

    if (this.corporate == null && this.property == null) {
      return;
    }
    console.log("load reco");
    this.recoSearchDto.maxDistance = 100;
    this.recoSearchDto.pageNumber = 0;
    this.recoSearchDto.pageSize = 50;
    this.recoSearchDto.centerMapLocation = new Poi();
    this.recoSearchDto.centerMapLocation.x = this.centerOfTheMap.x;
    this.recoSearchDto.centerMapLocation.y = this.centerOfTheMap.y;
    this.recoSearchDto.distanceReference = new Poi();
    if (this.relatedPoiForDistanceCalculation != null) {
      this.recoSearchDto.distanceReference.x = this.relatedPoiForDistanceCalculation.x;
      this.recoSearchDto.distanceReference.y = this.relatedPoiForDistanceCalculation.y;
    }
    if (this.property != null) {
      this.recoSearchDto.distanceReference.x = this.property.poi.x;
      this.recoSearchDto.distanceReference.y = this.property.poi.y;
    }
    if (this.propertyPoi != null) {
      this.recoSearchDto.distanceReference.x = this.propertyPoi.x;
      this.recoSearchDto.distanceReference.y = this.propertyPoi.y;
    }

    if (this.property != null) {
      this.recoSearchDto.propertyId = this.property.id;
    }
    if (this.corporate != null) {
      this.recoSearchDto.corporateId = this.corporate.id;
    }
    this.recoSearchDto.hostAdresses = this.showRecoCorporate;
    this.recoSearchDto.communityAdresses = this.showCommunityReco;
    this.recoSearchDto.influencerAdresses = this.showRecoInfluencer;
    this.recoSearchDto.yaagoAdresses = this.showRecoYaago;
    this.recoSearchDto.tagIds = this.filterTagsIds;
    this.recoSearchDto.categories = [];
    if (this.selectedCategory && this.selectedCategory !== 'all-categories') {
      this.recoSearchDto.categories.push(this.selectedCategory);
    }

    this.apiService.getRecommendations(this.recoSearchDto, this.language, null, this.recoSearchDto.corporateId).subscribe(
      res => {

        this.recommendationList = res;
        this.initRecommendations();
      },
      err => {}
    );
  }

  initMap() {
    console.log("this.mapId", this.mapId);

    if (!this.initializationMapDone && this.centerOfTheMap) {

      console.log("this.centerOfTheMap", this.centerOfTheMap);

      this.map = L.map(this.mapId + '', {
        center: [this.centerOfTheMap.y, this.centerOfTheMap.x],
        zoom: this.defaultZoom,
        zoomControl: false,

      });

      this.map.on('click', (e) => {
        this.doWeOpenRecommendationCardMini = false;
        this.doWeOpenRecommendationCard = false;
        this.currentRecommendation = null;
      });


      if (this.showZoomControl) {
        this.map.addControl(L.control.zoom({position: 'bottomright'}));
      }

      const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 22,
        minZoom: 3,

        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">Yaago</a>'
      });

      this.map.on('moveend', ($event) => {
        this.centerOfTheMap.x = this.map.getCenter().lng;
        this.centerOfTheMap.y = this.map.getCenter().lat;
        this.loadReco();
        //console.log('moveend:', $event);
        console.log('center:', this.map.getCenter());
      });

      tiles.addTo(this.map);

      //this.markerGroup = L.layerGroup().addTo(this.map);

      this.markerCluster = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        removeOutsideVisibleBounds: true,
        disableClusteringAtZoom: 14
      });

      this.map.addLayer(this.markerCluster);
      this.initializationMapDone = true;
    }
  }
  initProperties() {

  if (this.map) {

    if (this.properties != null) {

      console.log("list of properties", this.properties);
      let iconUrl: string | null = null;
      if (this.corporate != null) {
        if (this.corporate.bookletPropertyIconUrl != null) {
          iconUrl = this.corporate.bookletPropertyIconUrl;
        } else {
          iconUrl = this.corporate.favLogoUrl;
        }
      }
      if (iconUrl === '' || iconUrl == null) {
        iconUrl = "./assets/icon/recommendation/marker-property.svg";
      }
      console.log("iconUrl", iconUrl);
      this.properties.forEach(p => {
        const propertyIcon = L.icon({
          iconUrl: iconUrl,
          iconSize: [40, 40],
          iconAnchor: [17, 34]
        });
        var newMarker = new L.marker([p.poi.y, p.poi.x], {icon: propertyIcon})
          .on('click', (e) => {
            this.recommendationSelected.emit(e.target.options.reco);
          })
          .addTo(this.map);
      });
    }

    if (this.propertyPoi != null) {
      console.log("propertyPoi", this.propertyPoi);
      let iconUrl: string | null = null;
      if (this.corporate != null) {
        if (this.corporate.bookletPropertyIconUrl != null) {
          iconUrl = this.corporate.bookletPropertyIconUrl;
        } else {
          iconUrl = this.corporate.favLogoUrl;
        }
      }
      if (iconUrl === '' || iconUrl == null) {
        iconUrl = "./assets/icon/recommendation/marker-property.svg";
      }

      const propertyIcon = L.icon({
        iconUrl: iconUrl,
        iconSize: [40, 40],
        iconAnchor: [17, 34]
      });
      var newMarker = new L.marker([this.propertyPoi.y, this.propertyPoi.x], {icon: propertyIcon})
        .on('click', (e) => {
          this.recommendationSelected.emit(e.target.options.reco);
        })
        .addTo(this.map);
    }
  }

  }

  clickOnProperty(e) {
    console.log("click on property: ", e);
  }
  clickOnReco(e) {
    console.log("click on reco: ", e);
    console.log("yaaog reco : ", e.target.options.reco);
    console.log("doWeOpenRecommendationCard", this.doWeOpenRecommendationCard);

    const requestForTranslation : RequestForTranslation = new RequestForTranslation();
    requestForTranslation.recommendationId = e.target.options.reco;
    let personIds: string[] = [];
    if (e.target.options.reco.reviews != null) {
      e.target.options.reco.reviews.forEach(r => {
        if (personIds.indexOf(r.personId) < 0) {
          personIds.push(r.personId);
        }
      });
    }
    requestForTranslation.personIds = personIds;
    requestForTranslation.lang = this.language;
    this.apiService.getTranslatedComment(requestForTranslation).subscribe( res => {
      this.currentComments = res;
    });

    this.currentRecommendation = e.target.options.reco;
    if (this.currentRecommendation) {
      this._hasNext = this.currentRecommendation.photos.length > 1;
    }
  }

  initRecommendations() {
    this.markerCluster.clearLayers();
    //this.markerCluster.removeLayer(this.markers);
    //console.log('markers size', this.markers.length);
    //this.map.removeLayer(this.markers);
    //this.map.removeLayer();

    //this.markerGroup = L.layerGroup().addTo(this.map);
    // this.map.addLayer(this.markerCluster);

    //this.markerGroup.removeLayer();
    //this.markerGroup = L.layerGroup().addTo(this.map);

      this.markers = [];

      // console.log("recommendations Markers");
      if (this.recommendationList != null) {
        this.recommendationList.forEach(r => {
//          console.log(r);
//          console.log((r.influencerDetails != null) ? 'insta_' + r.category.key : (r.yaagoReco) ? 'yaago_' + r.category.key : r.category.key);
//          console.log(this.iconMarkers[(r.influencerDetails != null) ? 'insta_' + r.category.key : (r.yaagoReco) ? 'yaago_' + r.category.key : r.category.key] == null ? this.iconMarkers.recommendation : this.iconMarkers[(r.influencerDetails != null) ? 'insta_' + r.category.key : (r.yaagoReco) ? 'yaago_' + r.category.key : r.category.key].icon);

          let myComment: Comment | null = null;
          if (r.comment) {
            myComment = r.comment;
          }
          let whatToGetAsMarker = '';
          // console.log("Rec title", r.title);
          // console.log("Rec ", r);
          if (myComment != null && myComment.myFavorite) {
            whatToGetAsMarker = 'favorites';
          } else if (r.influencerDetails != null) {
            whatToGetAsMarker = 'insta_' + r.category.key;
          } else if (r.yaagoReco) {
            whatToGetAsMarker = 'yaago_' + r.category.key;

          }  else {
            whatToGetAsMarker = r.category.key;
          }
          // console.log("whatToGetAsMarker", whatToGetAsMarker);

          var newMarker = new L.marker([r.poi.y, r.poi.x],
            {
              riseOnHover: true,
              reco: r,
              icon: this.iconMarkers[whatToGetAsMarker] == null ? this.iconMarkers.recommendation : this.iconMarkers[whatToGetAsMarker].icon
              //icon: this.iconMarkers[(r.influencerDetails != null) ? 'insta_' + r.category.key : (r.yaagoReco && !this.corporate.onlyMineRecommendations) ? 'yaago_' + r.category.key : r.category.key] == null ? this.iconMarkers.recommendation : this.iconMarkers[(r.influencerDetails != null) ? 'insta_' + r.category.key : (r.yaagoReco && !this.corporate.onlyMineRecommendations) ? 'yaago_' + r.category.key : r.category.key].icon
            })
            .on('click', (e) => {
              this.recommendationSelected.emit(e.target.options.reco);
            });
            //.addTo(this.map);
            //.addTo(this.markerGroup);

          this.markerCluster.addLayer(newMarker);
          //this.markers.push(newMarker);

        });

    }


  }

  hasNext(): boolean {
    let ret = false;
    if (this.currentRecommendation != null) {
    if (this.currentRecommendation.photos.length > 1) {
      if (this.currentRecommendation && this.currentRecommendation.photos
        && this.currentRecommendation.photos.length > 0 && this.currentRecommendation.mainPhoto) {
        const index = this.getPhotoIndex(this.currentRecommendation.photos, this.currentRecommendation.mainPhoto);
        ret = index !== this.currentRecommendation.photos.length - 1;
      }
    }
    }
    return ret;
  }
  hasPrevious(): boolean {
    let ret = false;
    if (this.currentRecommendation && this.currentRecommendation.photos
      && this.currentRecommendation.photos.length > 0 && this.currentRecommendation.mainPhoto) {
      const index = this.getPhotoIndex(this.currentRecommendation.photos, this.currentRecommendation.mainPhoto);
      ret = index > 0;
    }
    return ret;
  }

  next() {
    if (this._hasNext) {
      this.index++;
      if (this.currentRecommendation != null) {
        this.currentRecommendation.mainPhoto = this.currentRecommendation.photos[this.index].replace('?aki_policy=medium', '');
      }
      this._hasNext = this.hasNext();
      this._hasPrevious = this.hasPrevious();
    }
  }

  previous() {
    if (this._hasPrevious) {
      this.index--;
      if (this.currentRecommendation != null) {
        this.currentRecommendation.mainPhoto = this.currentRecommendation.photos[this.index]; //.replace('?aki_policy=medium', '?aki_policy=large');
      }
      this._hasNext = this.hasNext();
      this._hasPrevious = this.hasPrevious();
    }
  }

  private getPhotoIndex(photos: string[], photoUrl: string) {
    if (photos && photoUrl) {
      const fPhoto = photoUrl; //.replace('?aki_policy=large', '?aki_policy=medium');
      return photos.findIndex(p => p.startsWith(fPhoto));
    } else {
      return -1;
    }
  }

  getFirstLetter(comment: Comment) {
    if (!comment) {
      return '';
    }
    if (comment.name) {
      return comment.name.substr(0, 1).toUpperCase();
    }
    return '';
  }

  showHideFilters() {
    this.showFilters = !this.showFilters;
  }
  close() {
   this.doWeOpenRecommendationCard = false;
   this.currentRecommendation = null;

  }

  initRecommendationMarkers() {
    console.log("recommandationMarkers creation");
    const iconBaseRecommendation = './assets/icon/recommendation/';
    const iconBaseRecommendationInstagram = './assets/icon/recommendation/instagram/';


    this.parcsAndOutdoors = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-parcsAndOutdoors.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. shops = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-shops.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. sports = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-sports.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. beauty = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-beauty.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. essentials = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-essentials.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. health = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-health.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. pubAndRestaurants = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-pubAndRestaurants.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. spots = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-spots.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. activities = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-activities.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. cars = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-cars.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. transports = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-transports.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. events = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-events.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. tesla = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-tesla.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. charger = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-charger.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this. localProducers = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-localProducers.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.services = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-services.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.favorites = L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-favorites.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });


    this.yaago_parcsAndOutdoors =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-5-parc.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_shops =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-8-magasin.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_sports =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-2-sports.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_beauty =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-10-beaute.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_essentials =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-7-essentiel.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_health =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-4-sante.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_pubAndRestaurants =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-11-restaurant.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_spots =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-3-monument.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_activities =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-6-activite.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_cars =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-9-voiture.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_transports =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-1-transport.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });

    this.yaago_events =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-12-events.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_tesla =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-14-tesla.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_charger =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-15_charger.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });

    this.yaago_localProducers =  L.icon({
      iconUrl: iconBaseRecommendation + 'yaago-13-localProducers.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_services =  L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-services.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.yaago_favorites =  L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-favorites.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });



    this.insta_parcsAndOutdoors =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-5-parc.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_shops =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-8-magasin.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_sports =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-2-sports.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_beauty =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-10-beaute.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_essentials =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-7-essentiel.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_health =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-4-sante.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_pubAndRestaurants =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-11-restaurant.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_spots =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-3-monument.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_activities =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-6-activite.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_cars =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-9-voiture.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_transports =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-1-transport.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_events = L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-12-events.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_localProducers =  L.icon({
      iconUrl: iconBaseRecommendationInstagram + 'yaago-13-localProducers.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_services =  L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-services.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });
    this.insta_favorites =  L.icon({
      iconUrl: iconBaseRecommendation + 'map-cluster-favorites.svg',
      iconSize: [40, 40],
      iconAnchor: [17, 34]
    });

    this.iconMarkers = {
      transports: { icon: this.transports },
      cars: { icon: this.cars },
      activities: { icon: this.activities },
      spots: { icon: this.spots },
      pubAndRestaurants: { icon: this.pubAndRestaurants },
      health: { icon: this.health },
      essentials: { icon: this.essentials },
      beauty: { icon: this.beauty },
      sports: { icon: this.sports },
      shops: { icon: this.shops },
      parcsAndOutdoors: { icon: this.parcsAndOutdoors },
      events: { icon: this.events },
      localProducers: { icon: this.localProducers },
      tesla: { icon: this.tesla },
      charger: { icon: this.charger },
      services: { icon: this.services },
      favorites: { icon: this.favorites },

      yaago_transports: { icon: this.yaago_transports },
      yaago_cars: { icon: this.yaago_cars },
      yaago_activities: { icon: this.yaago_activities },
      yaago_spots: { icon: this.yaago_spots },
      yaago_pubAndRestaurants: { icon: this.yaago_pubAndRestaurants },
      yaago_health: { icon: this.yaago_health },
      yaago_essentials: { icon: this.yaago_essentials },
      yaago_beauty: { icon: this.yaago_beauty },
      yaago_sports: { icon: this.yaago_sports },
      yaago_shops: { icon: this.yaago_shops },
      yaago_parcsAndOutdoors: { icon: this.yaago_parcsAndOutdoors },
      yaago_events: { icon: this.yaago_events },
      yaago_localProducers: { icon: this.yaago_localProducers },
      yaago_tesla: { icon: this.yaago_tesla },
      yaago_charger: { icon: this.yaago_charger },
      yaago_services: { icon: this.yaago_services },
      yaago_favorites: { icon: this.yaago_favorites },

      insta_transports: { icon: this.insta_transports },
      insta_cars: { icon: this.insta_cars },
      insta_activities: { icon: this.insta_activities },
      insta_spots: { icon: this.insta_spots },
      insta_pubAndRestaurants: { icon: this.insta_pubAndRestaurants },
      insta_health: { icon: this.insta_health },
      insta_essentials: { icon: this.insta_essentials },
      insta_beauty: { icon: this.insta_beauty },
      insta_sports: { icon: this.insta_sports },
      insta_shops: { icon: this.insta_shops },
      insta_parcsAndOutdoors: { icon: this.insta_parcsAndOutdoors },
      insta_events: { icon: this.insta_events },
      insta_localProducers: { icon: this.insta_localProducers },
      insta_services: { icon: this.insta_services },
      insta_favorites: { icon: this.insta_favorites },

    };

  }

  functionMove($event) {
    console.log("movened event fired:", $event);
  }

  isTagSelected(tagId: string) {
    return this.filterTagsIds.findIndex(item => item === tagId) > -1;
  }

  chooseTag(tagId: string) {
    if (!this.filterTagsIds) {
      this.filterTagsIds = [];
    }
    const existingIndex = this.filterTagsIds.findIndex(item => item === tagId);
    if (existingIndex > -1) {
      this.filterTagsIds.splice(existingIndex, 1);
    } else {
      this.filterTagsIds.push(tagId);
    }
    this.loadReco();
  }

  categoryChanged(event: any, initTags = true) {
    console.log(event);
    this.selectedCategory = event;
    this.categoryTags = this.allTags.filter(item => item.category === this.selectedCategory);
    if (initTags) {
      this.filterTagsIds = [];
    }
    this.loadReco();
  }
  getTags() {


  }
  getTagName(tagId: string) {
    if (!this.allTags) {
      return;
    }
    const tag = this.allTags.find(item => item.id === tagId);
    if (tag && tag.tags) {
      let translated = tag.tags.find(tr => tr.language === this.translateService.currentLang);
      translated = translated ? translated : tag.tags.find(tr => tr.language === 'fr');
      return translated.name;
    } else {
      return;
    }

  }

  openWebsite(url) {
    UtilsService.openLinkInNewTab(url);
  }

  openDirection(property: Property, recommendation: Recommendation) {
    console.log("GETTIGN THERE DIRECTION", this.property);
    /*if (this.whereIamPoi != null) {
      const url = 'https://www.google.com/maps/dir/' +
        this.whereIamPoi.y + ',' + this.whereIamPoi.x + '/' + recommendation.poi.y + ',' + recommendation.poi.x;
      UtilsService.openLinkInNewTab(url);
    } else {*/

      const url = 'https://www.google.com/maps/dir/' +
        property.poi.y + ',' + property.poi.x + '/' + recommendation.poi.y + ',' + recommendation.poi.x;
      UtilsService.openLinkInNewTab(url);
    //}
  }
  parseTags() {
    this.tags = [];
    this.guestService.getTags().subscribe(
      res => {
        if (this.currentRecommendation != null) {
          this.currentRecommendation.tagIds.forEach(tagId => {
            res.forEach((tagRes: any) => {
              if (tagRes.id === tagId) {
                tagRes.tags.forEach(el => {
                  if (el.language === this.language) {
                    this.tags.push(el);
                  }
                });
              }
            });
          });
        }
      },
      err => {
      }
    );
  }

  showMoreLessComment() {
    this.readMore = !this.readMore;
    if (this.readMore) {
      if (this.currentRecommendation != null && this.currentRecommendation.comment != null) {
        this.currentRecommendation.comment.comment = this.currentRecommendation.comment.commentLong;
      }
    } else {
      if (this.currentRecommendation != null && this.currentRecommendation.comment != null) {
        this.currentRecommendation.comment.comment = this.currentRecommendation.comment.commentShort;
      }
    }
    if (!this.readMore) {
      const element: HTMLElement | null =document.getElementById('avisSection');
      if (element != null) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }
    }
  }

  mustHide() {
    return this.recapVersion === 'v2' && this.screenWidth < 480;
  }
  seeMore() {
    this.doWeOpenRecommendationCardMini = false;
    this.doWeOpenRecommendationCard = true;
  }
}
