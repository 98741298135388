import {Injectable} from '@angular/core';
import {ConfigService} from '@ngx-config/core';

@Injectable({
  providedIn: 'root'
})
export class ConfService {

  constructor(private config: ConfigService) {
  }

  getBackendApiEndPoint() {
    try {


      /*const  url: string = this.config.getSettings('system.apiEndPoint');
      if (url == null || url.trim() === '') {
      } else {
        return url;
      }*/

      if (window.location.origin.indexOf("staging") > -1) {
        return window.location.origin.replace("guest.staging", "staging") + "/";
      } else if (window.location.origin.indexOf("demo") > -1) {
        return window.location.origin.replace("guest.demo", "demo") + "/";
      } else {
        return window.location.origin.replace("guest.", "app.") + "/";
      }



    } catch (exception) {
      console.log(exception);
      if (window.location.origin.indexOf("staging") > -1) {
        return window.location.origin.replace("guest.staging", "staging") + "/";
      } else if (window.location.origin.indexOf("demo") > -1) {
        return window.location.origin.replace("guest.demo", "demo") + "/";
      } else {
        return window.location.origin.replace("guest.", "app.") + "/";
      }
    }
  }

}
